body{
  background-color: #F8F8F8;
}

@font-face {
    font-family: Integral;
    src: url(../public/fonts/font1/IntegralCF-ExtraBold.ttf);
}

@font-face {
    font-family: Roboto;
    src: url(../public/fonts/font2/Roboto-Medium.ttf);
}

@font-face {
    font-family: Integral;
    src: url(../public/fonts/font1/IntegralCF-Medium.eot);
}

@font-face {
  font-family: Nexa;
  src: url(../public/fonts/font3/Nexa-Heavy.ttf);
}

@font-face {
  font-family: ZonaPro;
  src: url(../public/fonts/font3/ZonaPro-Bold.otf);
}

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.latest-image {
    transition: transform 0.2s;
  }
  
  .latest-image:hover {
    transform:scaleX(1.2);
    transition: 2s all ease;
    z-index: 999;
    object-fit: cover;
  }

  @media screen and (max-width:768px) {
    .latest-image:hover{
        transform: none;
    }
  }

  .cartoon-effect:hover {
    filter: saturate(200%) brightness(150%);
    transition: filter 0.3s ease;
  }

  .logos{
    overflow: hidden;
  }
  .logos-slide{
    white-space: nowrap;
    animation: 3000ms slide infinite linear;
  }

  @keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  .text-stroke {
    -webkit-text-stroke: 1px black; /* Set the stroke width and color */
    color: transparent; /* Make the text transparent to show only the stroke */
    animation: strokeAnimation 1s ease-in-out forwards; /* Apply stroke animation */
  }
  
  @keyframes strokeAnimation {
    from {
      stroke-dasharray: 1000; /* Set a large value to cover the entire text length */
      stroke-dashoffset: 1000;
    }
    to {
      stroke-dashoffset: 0;
    }
  }

  .hover-stroke:hover {
    -webkit-text-stroke: 1px black;
    color: #DF319A; 
    animation: strokeAnimation 1s ease-in-out forwards;
  }
  
  @keyframes strokeAnimation {
    from {
      stroke-dasharray: 1000; 
      stroke-dashoffset: 1000;
    }
    to {
      stroke-dashoffset: 0;
    }
  }

  
  .hover-stroke1:hover {
    -webkit-text-stroke: 1px white; /* Set the stroke width and color */
    color: transparent; /* Make the text transparent to show only the stroke */
    animation: strokeAnimation 1s ease-in-out forwards; /* Apply stroke animation */
  }
  
  @keyframes strokeAnimation {
    from {
      stroke-dasharray: 1000; /* Set a large value to cover the entire text length */
      stroke-dashoffset: 1000;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
  .hover-stroke2{
    -webkit-text-stroke: 1px white; /* Set the stroke width and color */
    color: transparent; /* Make the text transparent to show only the stroke */
    animation: strokeAnimation2 1s ease-in-out forwards; /* Apply stroke animation */
  }

    
  @keyframes strokeAnimation2 {
    from {
      stroke-dasharray: 1000; /* Set a large value to cover the entire text length */
      stroke-dashoffset: 1000;
    }
    to {
      stroke-dashoffset: 0;
    }
  }

  .hover-stroke3{
    -webkit-text-stroke: 1px black; /* Set the stroke width and color */
    color: transparent; /* Make the text transparent to show only the stroke */
    animation: strokeAnimation3 1s ease-in-out forwards; /* Apply stroke animation */
  }

    
  @keyframes strokeAnimation3 {
    from {
      stroke-dasharray: 1000; /* Set a large value to cover the entire text length */
      stroke-dashoffset: 1000;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
  
/* Fshij dots-it nga karusela */
.react-multi-carousel-dot-list {
  display: none;
}

/* Stilizo dots-it si katrorë */
.react-multi-carousel-dot {
  width: 15px;
  height: 5px;
  border-radius: 0;
  background-color: #6E6E6E; 
  margin: 4px 8px; 
}

/* Stilizo dots-it aktiv */
.react-multi-carousel-dot--active {
  background-color: #ffffff;
}

.imageService{
  -webkit-mask-image: linear-gradient(to right, transparent 10%, black 100%);
  mask-image: linear-gradient(to right, transparent 10%, black 100%);
}

.workImage{
  -webkit-mask-image: linear-gradient(to top, transparent 1%, black 30%);
  mask-image: linear-gradient(to top, transparent 1%, black 30%);
}

.slide-in{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  transform-origin: top;
}
.slide-out{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  transform-origin: bottom;
}
.textTransition{
  color: white;
  z-index: 1;
  margin: 0;
  white-space: nowrap;
  margin-top: auto;
  transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.slider{
  margin:  auto;
  overflow: hidden;
  position: relative;
  width: auto;
  justify-content: center;
  align-items: center;
}
.slider .slide-track{
  animation: scroll 40s linear infinite;
  display: flex;
  padding: 50px;
  width: (250px * 14);
}
.slider .slide{
  height: 100px;
  width: 250px;
}

@keyframes scroll {
  0%{
    transform: translateX(0);
  }
  100%{
    transform: translateX(calc(-250px * 7));
  }
}
  /* .accordion{
    width: 100%;
  
    justify-content: center;
    height: 693px;
    gap: 10px;
    transition: 0.3s;
  }
  .accordion:hover{
    gap: 4px;
  }
  .accordion h2{
    font-weight: 400;
    font-size: 24px;
    line-height: 45px;
    border-bottom: 1px solid #fff;
    white-space: nowrap;
  }
  .accordion li{
    position: relative;
    overflow: hidden;
    flex: 0 0 260px;
    cursor: pointer;
  }
  .accordion li img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

  .accordion li,
  .accordion li img,
  .accordion li .content,
  .accordion li .content span {
    transition: 0.5s;
  }
  .accordion li .content{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    color: #fff;
    padding: 15px;
    background: #02022e;
    background: linear-gradient(0deg, rgb(0 0 0 / 70%) 10%, rgb(255 255 255 / 0%) 100%);
    opacity: 0;
    visibility: hidden;
  }
  .accordion li
  .content span{
    position: absolute;
    z-index: 3;
    left: 40%;
    bottom: 50px;
    translate: -300px 0;
    visibility: hidden;
    opacity: 0;
  }
  .accordion li:hover{
    flex: 0 0 450px;
    scale: 1;
    z-index: 10;
    opacity: 1;
  }
  .accordion li:hover
  .content {
    opacity: 1;
    visibility: visible;
  }
  .accordion li:hover span{
    translate: -50% 0;
    opacity: 1;
    visibility: visible;
  }

  .gallery-wrap{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 90vh;
  }
  .item{
    flex: 1;
    height: 100%;
    
    transition: all 2s ease;
  }
  .item:hover{
    flex: 2;
  } */

  .firstServiceImage {
    -webkit-mask-image: linear-gradient(to right, transparent 1%, black 30%);
    mask-image: linear-gradient(to right, transparent 1%, black 30%);
  }
  

/* .bg-trek{
  background-image: url(../../trekuartista-frontend/src/Components/Assets/Texturelabs_Paper_203L.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
} */


